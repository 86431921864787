import { BrowseSubjects, BrowseSubjectsMobile } from '@ck12/browse-subjects';
import React from 'react';
import ReactDOM from 'react-dom';
import Helmet from 'react-helmet';

const LandingBrowse = (props) => {
  const { showHeader } = props;
  let metaDescription =
    'Browse all CK-12’s thousands of Concepts and FlexBooks by grade level, subject, branch, language, or country of origin. Concepts are composed of text, video, math and science interactives, and adaptive practice questions.';
  return (
    <div>
      <Helmet title="K-12 FlexBooks & Concepts | CK-12 Foundation">
        <meta property="og:description" name="description" content={metaDescription} />
      </Helmet>
      <div className="hide-for-medium-down">
        <BrowseSubjects showHeader={showHeader} />
      </div>
      <div className="show-for-medium-down">
        <BrowseSubjectsMobile />
      </div>
    </div>
  );
};

export const BrowseInitializer = {
  init(el, showHeader) {
    ReactDOM.render(<LandingBrowse showHeader={showHeader} />, el);
  },
};

window.BrowseInitializer = BrowseInitializer;
